import Button from '@material-ui/core/Button';
import React from 'react';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import { useShowSnackbarMessage } from '../../../api/snackbars';
import CustomerProfilePageCreateMasterUserDialog from './CustomerProfilePageCreateMasterUserDialog';
import { SidiaryUserCreateFormValues, useSidiaryUserCreateHandler } from './useSubsidiaryUsersCreateHandler';

export interface CustomerProfilePageCreateSubsidiaryUserButtonProps {
  handleCreated?: () => void;
  sidiary: {
    id: string;
    name: string;
  };
}

const CustomerProfilePageCreateSubsidiaryUserButton: React.FC<CustomerProfilePageCreateSubsidiaryUserButtonProps> = ({
  handleCreated,
  sidiary,
}) => {
  const [open, setOpen] = React.useState(false);
  const createUser = useSidiaryUserCreateHandler();
  const t = useTranslateWithFallback();
  const showMessage = useShowSnackbarMessage();
  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);
  const handleSave = React.useCallback(
    async (userData: SidiaryUserCreateFormValues) => {
      createUser({ ...userData, username: userData.email, sidiary: sidiary.id })
        .then((res) => {
          if ('status' in res && res.status === 'error') {
            showMessage(res.msg, { variant: 'error' });
            return;
          }
          if (handleCreated) {
            handleCreated();
          }
        })
        .finally(() => handleClose());
    },
    [createUser, handleClose, handleCreated, sidiary.id],
  );

  return (
    <>
      <Button style={{ marginTop: '20px' }} variant='contained' color='primary' onClick={() => setOpen(true)}>
        {t('app/addUser')}
      </Button>
      <CustomerProfilePageCreateMasterUserDialog
        open={open}
        onSave={handleSave}
        onFormReleased={handleClose}
        onClose={handleClose}
        sidiary={sidiary}
      />
    </>
  );
};

CustomerProfilePageCreateSubsidiaryUserButton.displayName = 'CustomerProfilePageCreateSubsidiaryUserButton';

export default CustomerProfilePageCreateSubsidiaryUserButton;
