import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { useRecoilValue } from 'recoil';
import useRouteUrlFactory from '../../../hooks/useRouteUrlFactory';
import { customNavigationLinks, projectName, showContactPage } from '../../../config';
import { localeState } from '../../../api/appState';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import { useAdminConfig } from '../../../adminConfig';

export interface AppFooterProps {
  handleDrawerToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: '40px',
      backgroundColor: theme.palette.appFooter.background,
      color: theme.palette.appFooter.color,
      display: 'flex',
      padding: theme.spacing(2),
      '& ul': {
        listStyle: 'none',
        paddingLeft: 0,
      },
      '@media (max-width: 1100px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    navLink: {
      color: theme.palette.appFooter.color,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    logoHolder: {
      minWidth: '280px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      '& img': {
        maxHeight: '50px',
      },
    },
    lists: {
      display: 'flex',
      gap: '40px',
      width: '100%',
      justifyContent: 'space-evenly',
      '& h3': {
        marginTop: 0,
      },
      '@media (max-width: 490px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    copyright: {
      fontSize: '12px',
    },
    contactBox: {
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        minWidth: '380px',
        '& > h3': {
          marginTop: 0,
        },
        '& > a': {
          color: theme.palette.appFooter.color,
          breakWord: 'nobreak',
        },
        '@media (max-width: 490px)': {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
  }),
  { name: 'AppFooter' },
);

export default function AppFooter(): JSX.Element {
  const classes = useStyles();
  const t = useTranslateWithFallback();
  const routeUrlFactory = useRouteUrlFactory();
  const currentYear = new Date().getFullYear();
  const { footerContactBox, footerHeadquarters, staticPageList } = useAdminConfig();

  const locale = useRecoilValue(localeState);

  return (
    <div className={classes.root}>
      <div className={classes.logoHolder}>
        <img src={`/${projectName}/mainLogo_small.png`} alt={`${projectName} logo`} />
        <p className={classes.copyright}>
          © {currentYear} {t('mainNavigation/allRightsReserved')}
        </p>
      </div>
      <div className={classes.lists}>
        <div>
          <h3>{t('mainNavigation/aboutUs')}</h3>
          <ul>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('homePage')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/introduction')}
              </Link>
            </li>
            {showContactPage && (
              <li>
                <Link
                  component={RouterLink}
                  to={routeUrlFactory('contacts')}
                  color='textPrimary'
                  variant='inherit'
                  className={classes.navLink}
                  noWrap
                >
                  {t('mainNavigation/contacts')}
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div>
          <h3>{t('mainNavigation/allAboutShopping')}</h3>
          <ul>
            <li>
              <Link
                component={RouterLink}
                to={routeUrlFactory('catalogue')}
                color='textPrimary'
                variant='inherit'
                className={classes.navLink}
                noWrap
              >
                {t('mainNavigation/products')}
              </Link>
            </li>
            {customNavigationLinks &&
              customNavigationLinks.map((link, idx) => (
                <li key={idx}>
                  <a href={link.href} target='_blank' rel='noreferrer' className={classes.navLink}>
                    {link.label[locale]}
                  </a>
                </li>
              ))}
            {staticPageList?.map((i) => (
              <li key={i._id}>
                <Link
                  component={RouterLink}
                  to={routeUrlFactory('staticPage', {
                    params: { group: i.group, locale },
                  })}
                  color='textPrimary'
                  variant='inherit'
                  className={classes.navLink}
                  noWrap
                >
                  {i.title || i.group}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {footerHeadquarters?.body && <div dangerouslySetInnerHTML={{ __html: footerHeadquarters.body }} />}
      </div>
      {footerContactBox?.body && (
        <div className={classes.contactBox} dangerouslySetInnerHTML={{ __html: footerContactBox.body }} />
      )}
    </div>
  );
}
