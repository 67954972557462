import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { apiClientState } from '../../../api/appState';

export type SubsidiaryUser = SidiaryUser & { client: string; disabled?: boolean };

export type SidiaryUser = {
  deliveryPlaces?: Array<string>;
  email: string;
  id: string;
  name: string;
  orderTotalLimit?: number;
  phone: string | undefined;
  supervisor?: string;
  username: string;
};

export type SidiaryError = {
  msg: string;
  status: 'error';
};

export function useUsersCallList(): (customer: string) => Promise<SubsidiaryUser[]> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (customer: string) =>
      apiClient.get<SubsidiaryUser[], { customer: string }>('/v1/users/find-by-customer', { customer }),
    [apiClient],
  );
}

export default function useSubsidiaryUsersList(
  customer: string,
  excludeUserName?: string,
): [boolean, () => void, SubsidiaryUser[]] {
  const [{ loading, users }, setSubsidiaryUsers] = useState<{ loading: boolean; users?: SubsidiaryUser[] }>({
    loading: true,
    users: [],
  });
  const filterUsers = useCallback(
    (users: SubsidiaryUser[]) =>
      excludeUserName != null ? users.filter((user) => !user.username.includes(excludeUserName)) : users,
    [excludeUserName],
  );
  const getSubsidiaryUsers = useUsersCallList();
  useEffect(() => {
    getSubsidiaryUsers(customer).then((us: SubsidiaryUser[]) =>
      setSubsidiaryUsers({ loading: false, users: filterUsers(us) }),
    );
  }, [customer, filterUsers, getSubsidiaryUsers, setSubsidiaryUsers]);
  const reloadUsers = useCallback(() => {
    setSubsidiaryUsers({ loading: true });
    getSubsidiaryUsers(customer).then((us: SubsidiaryUser[]) =>
      setSubsidiaryUsers({ loading: false, users: filterUsers(us) }),
    );
  }, [customer, filterUsers, getSubsidiaryUsers]);

  return [loading, reloadUsers, users || []];
}

export function useSidiaryUsersList(customer: string): [boolean, () => void, SidiaryUser[]] {
  const [{ loading, users }, setSidiaryUsers] = useState<{ loading: boolean; users?: SidiaryUser[] }>({
    loading: true,
    users: [],
  });
  const getSidiaryUsers = useUsersCallList();
  useEffect(() => {
    getSidiaryUsers(customer).then((users: SidiaryUser[]) => setSidiaryUsers({ loading: false, users }));
  }, [customer, getSidiaryUsers, setSidiaryUsers]);
  const reloadUsers = useCallback(() => {
    setSidiaryUsers({ loading: true });
    getSidiaryUsers(customer).then((users: SidiaryUser[]) => setSidiaryUsers({ loading: false, users }));
  }, [customer, getSidiaryUsers]);

  return [loading, reloadUsers, users || []];
}
